@import '../../../shared/scss-helpers/index.scss';

// shared styles across all banners
.banner {
  display: flex;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  color: $black;
  justify-content: space-evenly;
  z-index: 50;

  &__Information {
    align-items: center;
    font-family: 'nyt-franklin';
    @include font-size(16);
  }
}

.arrow {
  transform: rotate(180deg) translateX(100%);
  padding: 0;
  width: 15px;
  margin-left: 4%;
  background: $white;
  border: 0;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

// SINGLE ICON --------------------------------------------------------
.singleIconBanner {
  @extend .banner;
  min-height: 118px;

  @media (max-height: 600px) {
    min-height: 90px;
  }

  @media (max-height: 550px) {
    min-height: 70px;
  }

  .arrow {
    order: 3;
    padding-right: calc(var(--horizontal-spacing-0-5) * 3.75);
  }

  .icon {
    max-width: 100px;
    margin-left: 0.8rem;

    &_wordlebot {
      content: var(--wordlebot-walking-icon);
      width: 62px;
    }

    // Wordle Archive is not currently in use - XWD-27036
    &_wordleArchive {
      margin-left: 0;

      svg {
        height: 100%;
        border-radius: 4px;
      }
    }

    &_connectionsStats {
      margin-left: 0;
      width: 100px;
      content: url('https://www.nytimes.com/games-assets/v2/assets/connections/connections_CTA.gif');
    }

    &_connectionsArchive {
      margin-left: 0;
      width: 70px;
      content: url('https://www.nytimes.com/games-assets/v2/assets/connections/connections-archive-cta.svg');
    }

    &_miniArchive {
      width: 70px;
      content: url('https://www.nytimes.com/games-assets/v2/assets/icons/mini_archive.png');
    }

    &_miniLeaderboard {
      margin: 0 calc(var(--horizontal-spacing-0-5) * 2.5) 0 0;
      width: 86px;
      content: url('https://www.nytimes.com/games-assets/v2/assets/icons/mini_leaderboard.png');
    }
  }

  // contains icon, title, and message
  &__Information {
    @extend .banner__Information;
    margin-left: 2%;
    display: flex;
    justify-content: space-between;
    white-space: pre-wrap;
  }

  &__BodyContent {
    margin-left: 3%;
    width: 230px;
    text-align: left;

    h3 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
    }
  }
}

@include tablet {
  .singleIconBanner {
    justify-content: center;
  }
}

// banner should not take up whole screen width for tablet landscape
@media (orientation: landscape) {
  .singleIconBanner {
    align-self: center;
    justify-content: center;
    width: 600px;
    margin: 0 auto;

    // move the arrow icon over
    .arrow {
      left: 520px;
    }
  }

  .dropShadow {
    box-shadow: 0 3px 12px -1px rgba(0, 0, 0, 0.3);
  }
}

// MULTI ICON --------------------------------------------------------
.multiIconBanner {
  @extend .banner;
  padding: 1rem;
  border-top: 1px solid $black;

  &__Information {
    @extend .banner__Information;
    margin: auto;
    text-align: center;
    font-weight: $weight-bold;

    .iconHolder {
      text-align: center;
      margin: auto;
      background: url('/public/assets/icons/inline-games-all.svg')
        no-repeat center/contain;
      height: 30px;
    }
  }

  &__BodyContent {
    flex: 1;
    h3 {
      font-size: 0.85rem;
    }
  }
}

// GRID --------------------------------------------------------
.gridBanner {
  @extend .banner;
  height: 100px;
  justify-content: space-between;

  &_wordle {
    min-height: 100px;
  }

  &__AnimationContainer {
    height: 100%;
    width: 110px;
    background: url('/public/assets/icons/games-grid.svg') no-repeat
      center/cover;
  }

  &__Information {
    @extend .banner__Information;
    display: flex;
    height: 100%;
    justify-content: center;
    font-size: 16px;
    font-family: 'nyt-karnak';
    font-weight: 600;
    margin-right: calc(var(--horizontal-spacing-0-5) * 2.5);
    text-align: left;
  }

  &__BodyContent {
    flex: 1;
    p {
      padding: 0 2% 0 7%;
    }
  }
}

$tablet-width: 600px;

@include tablet-and-desktop {
  .gridBanner {
    display: flex;
    align-self: center;
    justify-content: space-between;
    min-height: 150px;
    max-width: $tablet-width;
    left: calc(50% - #{$tablet-width/2});

    &Container {
      height: 0;
    }

    &__AnimationContainer {
      min-width: 154px;
    }

    &__Information {
      font-size: 28px;
      line-height: 32.2px;
    }

    .arrow {
      margin-right: 2%;
    }
  }

  :global(.xwd__modal--content p) {
    font-size: unset;
    line-height: unset;
  }
}

@include samsung-tablets-landscape {
  .gridBanner {
    display: none;
  }
}

// STRANDS --------------------------------------------------------
.strandsBanner {
  display: flex;
  justify-content: space-evenly;
  padding: 0.5em 0.6em 1em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  color: $black;
  z-index: 50;

  @media screen and (max-device-width: 1280px) and (orientation: landscape) {
    position: sticky;
  }

  &.relative {
    position: relative;
  }

  &__title {
    margin-bottom: 0.2em;
    font-weight: 700;
    font-size: 16px;
    line-height: 20.8px;
    color: #0051b7;
    text-align: center;
  }

  &__subtitleContainer {
    text-align: center;
    font-weight: bold;
    > p {
      font-size: 14px;
    }
  }

  &__longIconContainer {
    max-width: fit-content;
    margin-inline: auto;
    position: relative;
  }

  button.iconButton {
    background: $white;
    border: 0;
    cursor: pointer;

    padding: 0;
  }

  .bannerInformationTest {
    align-items: center;
    font-family: 'nyt-franklin';
    margin: auto;
    @include font-size(16);
    text-align: center;
    i {
      height: 20px;
      width: 20px;
    }
  }

  .bannerBodyTest {
    flex: 1;

    h3 {
      font-size: 0.85rem;
      font-weight: $weight-bold;
      margin: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

.strandsIcon {
  text-align: center;
  margin: auto;
  background: url('/public/assets/icons/strands-download.svg')
    no-repeat center/contain;
  height: 30px;
  width: 240px;
}

.caretRightContainer {
  position: absolute;
  top: 0;
  right: -2.2em;

  @media (min-width: 285px) {
    right: -2.4em;
  }

  @media (min-width: 315px) {
    right: -3em;
  }

  @media (min-width: 345px) {
    right: -3.5em;
  }

  @media (min-width: 390px) {
    right: -4em;
  }
  button {
    background-color: none;
  }
}

// APP AD ICON --------------------------------------------------------
.appAdIconBanner {
  @extend .banner;
  width: 342px;
  min-height: 80px;
  margin: 0 auto;
  border-radius: 10px;
  bottom: 50px;
  padding: 0;

  .adArrow {
    order: 3;
    width: 10px;
    margin-left: var(--horizontal-spacing-2-5);
  }

  &__Content {
    @extend .banner__Information;
    display: flex;
    width: 256px;
  }

  &__Information {
    @extend .singleIconBanner__BodyContent;

    p {
      margin-top: var(--vertical-spacing-0-5);
    }
  }

  .appAdIcon {
    margin: 0 0 auto;
    width: 20px;
    content: url('https://www.nytimes.com/games-assets/v2/assets/icons/ad-message-icon.svg');
  }
}

.appAdIconBannerStrands {
  bottom: 30px;
  min-height: 40px;

  &__Content {
    margin: var(--vertical-spacing-1) 0;
  }

  .adArrow {
    order: 3;
    width: 10px;
    margin: auto 0 auto var(--horizontal-spacing-2-5);
  }
}

@include tiny-phone {
  .appAdIconBanner {
    bottom: 10px;
    min-height: 40px;

    &__Content {
      margin: var(--vertical-spacing-1) 0;
    }
  }
}

@keyframes slideDown {
  0% {
    transform: translateY(0%);
  }
  90% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes flyIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flyOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@import '../../../shared/scss-helpers/mixins.scss';
@import '../../../shared/scss-helpers/colors.scss';

.earnedDate {
  font-family: nyt-franklin;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 12px;
}

.imageContainer {
  margin-top: calc(var(--vertical-spacing-0-5) * 4.5);
  height: 140px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: var(--vertical-spacing-3);
  font-size: 34px;
  line-height: 1;
  font-family: 'nyt-franklin';
  font-weight: 300;
  display: flex;
  align-items: center;

  .pill {
    background-color: black;
    color: white;
    border-radius: 50%;
    margin-left: var(--horizontal-spacing-1);
    font-weight: bold;
    height: 36px;
    width: 36px;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.description {
  margin-top: var(--vertical-spacing-4);
  font-size: 14px;
  font-weight: 500;
  font-family: 'nyt-franklin';
}

.buttonContainer {
  margin-top: var(--vertical-spacing-4);
  display: flex;
  flex-direction: column;
  align-items: center;

  button,
  a {
    width: 200px;
  }

  :global(button.pz-moment__button.secondary.default),
  :global(a.pz-moment__button.secondary.default) {
    color: var(--bg-btn-emphasis);
    border: 1px solid var(--bg-btn-emphasis);
  }
}

.detailBody {
  padding-top: var(--vertical-spacing-7);
}

@include desktop {
  .detailBody {
    width: 540px;
    height: 540px;
  }
}

.timeline {
  z-index: -1;
  $image-height: 40px;
  $timeline-height: 4px;

  position: absolute;
  width: 100%;
  height: $timeline-height;

  display: flex;
  background-color: $gray10;

  &.fadePrev {
    background: linear-gradient(
      to left,
      $gray10 50%,
      transparent 80%
    );
  }

  &.fadeNext {
    background: linear-gradient(
      to right,
      $gray10 50%,
      transparent 80%
    );
  }

  img {
    height: $image-height;
    width: unset;
  }

  .prev,
  .next {
    position: absolute;
    top: -0.5 * ($image-height - $timeline-height);
  }

  .prev {
    left: 16%;
  }

  .next {
    right: 16%;
  }
}

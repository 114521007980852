.imageContainer {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &.pop {
    animation: pop-in 1s;
    animation-delay: 750ms;
  }

  img {
    height: 100%;
  }

  .progressPill {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 2em;
    width: 2em;
    background-color: black; // TODO: dark mode
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;

    &.large {
      font-size: 24px;
    }

    &.none {
      display: none;
    }
  }
}

.holidayPill {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 1.25;
  font-weight: bold;
  padding: 0 0.25em;
  background-color: black; // TODO: dark mode
  color: white;

  &.large {
    font-size: 24px;
  }
}

@keyframes pop-in {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.8);
  }
}

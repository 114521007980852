/*
  These colors are also in src/games/shared/colors/colors.js
*/

$black: #000;
$white: #fff;
$clear: transparent;
$wordleBlack: #212121;
$wordleBlack2: #272729;
$wordleBlack3: #1a1a1b;
$wordleBlack4: #121213;
$wordleBlack5: #2f2f31;

$blue1: #083aaa;
$blue2: #2860d8;
$blue3: #4f85e5;
$blue35: #5aa0d5;
$blue4: #a7d8ff;
$blue5: #dcefff;
$blue7: #477aaa;
$newsLightContentAccent: #346eb7;
$newsDarkContentAccent: #6ba1dd;
$purple1: #5960ec;
$outlineBlue: #2873dc;

$error-red: #ce2424;

$gray1: #333;
$gray2: #959595;
$gray3: #ccc;
$gray4: #dcdcdc;
$gray5: #e6e6e6;
$gray6: #f4f4f4;
$gray7: #fafafa;
$gray8: #c4c4c4;
$gray9: #c2c2c2;
$gray10: #d9d9d9;
$gray11: #eee;
$gray12: #ebebeb;
$calendarGray: #979797;

// Grays used in Wordle
$gray13: #d3d6da;
$gray18: #787c7e;
$gray19: #878a8c;
$gray20: #edeff1;
$gray21: #f6f7f8;
$gray22: #e3e3e1;
$gray23: #a6a6a6;
$gray24: #818384;
$gray25: #565758;
$gray26: #3a3a3c;
$gray27: #424242;
$gray28: #59595a;
$gray29: #afafaf;
$gray30: #5a5a5a;

$newsGray10: #dfdfdf;
$newsGray25: #c7c7c7;
$newsGray50: #8b8b8b;
$newsGray60: #727272;
$newsGray85: #363636;
$newsGray100: #121212;

$newsDarkContentPrimary: #f8f8f8;

$blueGray1: #787886;
$blueGray2: #e4eaef;
$blueGray3: #ecf0f3;
$blueGray4: #c9d1d9;
$blueGray5: #b3bdc4;

$gold1: #c4a200;
$gold2: #e2c32f;
$gold3: #dcbc3f;
$gold4: #e2c400;

$yellow1: #ffda00;
$yellow2: #ffeca0;
$yellow3: #c9b458;
$yellow4: #b59f3b;

$red1: #e63333;
$red2: #e05c56;
$red3: #a90111;

$green1: #6dc3a1;
$green4: #538d4e;

$statsPink: #f93aa7;
$statsYellow: #ffc600;

$spelling-bee-yellow: #f8cd05;
$letter-boxed-pink: #faa6a4;
$bannerBlue: #4d88f9;
$setPurple: #89529d;
$setPurpleDark: #641153;

// Navigation
$daily-crossword-blue: #6493e6;
$mini-crossword-blue: #95befa;
$spelling-bee-gold: #f7da21;
$tiles-green: #b5e352;
$letter-boxed-red: #fc716b;
$sudoku-orange: #fb9b00;
$wordleGreen: #6aaa64;
$strands-seafoam: #c0ddd9;
$connections-periwinkle: #b4a8ff;
$pips-pink: #daa8d0;

// Wordle Colorblind colors
$wordleColorblindOrange: #f5793a;
$wordleColorblindBlue: #85c0f9;
$wordleHighContrastGreen: #58a351;

@use '../../../../shared/scss-helpers/fonts.scss';

.videoContainer {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.playButton {
  background-image: url('/public/assets/play.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.306);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #00000045;
  z-index: 2;
}

.hidePlayButton {
  display: none;
}

.buttonContainer {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  gap: 12px;
  padding: 20px 20px;
  align-items: flex-end;
  z-index: 2;
}

.button {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: none;
  position: relative;
  border-radius: 50%;
}

.hidden {
  visibility: hidden;
}

.img {
  width: auto;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.clickContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 1;
}

.clickThroughText {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 20px;
  padding-top: 20px;
  color: #fff;
  font:
    600 12px fonts.$inter,
    san-serif;
}

:root {
  --inter-ad-skip-button-height: 52px; // max height of skip button, used in Skip.module.scss
  --inter-ad-top-bar-height: 34px; // top bar advertisement slug
  --inter-ad-bottom-bar-height: 24px; // bottom bar just below ad
}

.interstitialPlaceholder:before {
  content: 'advertisement';
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #363636;
  font-size: 12px;
  border: 1px solid #363636;
  padding: 12px 18px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: fadeIn 0.5s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ad {
  max-height: calc(
    100dvh -
      (
        var(--inter-ad-skip-button-height) +
          var(--inter-ad-top-bar-height) +
          var(--inter-ad-bottom-bar-height)
      )
  );
  & > div {
    margin: auto;
  }
}

.adSlug {
  margin: auto;
  width: 100%;
  min-height: 250px;
  text-align: center;
  opacity: 0;
  overflow: hidden;

  & h3 {
    font-family: 'nyt-franklin';
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    margin: 12px auto 10px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #5a5a5a;
    font-style: normal;
  }

  &.adSlugVisible {
    padding-bottom: 24px;
    background-color: #f8f8f8;
    opacity: 1;
  }
}

.fluid {
  width: 100%;
}

.modalOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 0;
  border: none;
  justify-content: space-between;
  align-items: center;
  background-color: #e3e3e1;
  z-index: var(--modal-z-index);
  &.hideView {
    display: none;
  }
  &.animateIn {
    animation: fadeIn 3s ease-in;
    &.shortenFadeIn {
      animation: fadeIn 1s ease-in;
    }
  }
}

.carousel {
  padding-bottom: var(--vertical-spacing-1-5);
  width: calc(100vw - 24px);
  max-width: 424px;

  display: flex;
  overflow-x: auto;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  > button {
    flex-shrink: 0;
    width: 80%;
    min-width: 250px;
    margin-right: var(--horizontal-spacing-2);
  }

  &.center {
    justify-content: center;

    > button {
      margin-right: 0;
      width: calc(100% - 18px);
    }
  }

  &.scrollSnap {
    scroll-snap-type: x mandatory;

    > button {
      scroll-snap-align: start;
    }
  }
}

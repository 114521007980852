@import '../../../shared/scss-helpers/index.scss';

.pill {
  font-family: $franklin;
  font-weight: $weight-bold;
  font-size: 0.625rem;
  line-height: 0.75rem;
  background: $newsGray85;
  color: $white;
  height: 24px;
  display: flex;
  text-transform: uppercase;
  border-radius: 6px;
  letter-spacing: 0.05rem;
  white-space: nowrap;
  padding: 5px 8px;
  align-items: center;

  // default pill does not assume absolute positioning
  &.default {
    position: static;
  }

  &.top-right-edge {
    position: absolute;
    top: 1em;
    right: 1em;
  }

  // For placement on a card's button
  &.top-right-edge-inner {
    position: absolute;
    top: -1em;
    right: -0.1em;
  }
}

@include phone {
  .pill {
    &.top-right-edge {
      top: 0.75em;
      right: 0.75em;
    }
  }
}

.badge {
  all: unset;
  height: 44px;
  border: 1px solid var(--bg-btn-emphasis);
  border-radius: 4px;
  // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  padding: 8px;
  background-color: var(--bg-page);
  color: var(--text);

  display: flex;
  align-items: center;
}

// This is for Wordle
:global(body.dark) {
  .badge {
    border: 1px solid var(--color-tone-1);
    background-color: var(--color-tone-7);
    color: var(--color-tone-1);
  }
}

.displayName {
  flex: 1;

  text-align: left;
  margin-left: var(--horizontal-spacing-1);
  margin-right: var(--horizontal-spacing-1);
  font-size: 20px;
  font-family: 'nyt-franklin';
  font-weight: 300;
}

.pill {
  height: 10px;
}
